<template>
  <!--收支明细记录-->
  <div style="padding: 20px;">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
                :model="where"
                label-width="90px"
                class="ele-form-search"
                @keyup.enter.native="reload"
                @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="关键字:">
                    <el-input
                        clearable
                        v-model="where.keyword"
                        placeholder="请输入关键字"/>
                  </el-form-item>
                </el-col>
                <el-col :lg="8" :md="12">
                  <el-form-item label="交易时间:">
                    <el-date-picker
                    unlink-panels
                       style="width: 100%!important;"
                       value-format="yyyy-MM-dd"
                       v-model="where.time"
                       type="daterange"
                       range-separator="至"
                       start-placeholder="开始日期"
                       end-placeholder="结束日期">
                    </el-date-picker>
                    <!-- <DatePicker v-model="where.time" :transfer="true" @on-change="datechange" format="yyyy-MM-dd" type="daterange" placement="bottom-end" placeholder="请选择"/> -->
                  </el-form-item>
                </el-col>
                <!--<el-col :lg="4" :md="12">-->
                <!--  <el-form-item label="项目:">-->
                <!--    <el-select clearable v-model="where.value" placeholder="请选择">-->
                <!--      <el-option label="综合" value="1"/>-->
                <!--      <el-option label="综合1" value="2"/>-->
                <!--      <el-option label="综合2" value="3"/>-->
                <!--    </el-select>-->
                <!--  </el-form-item>-->
                <!--</el-col>-->
                <el-col :lg="6" :md="12">
                  <el-form-item label="类型:">
                    <el-select clearable v-model="where.pm" placeholder="请选择">
                      <el-option label="支出" value="0"/>
                      <el-option label="收入" value="1"/>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="15">
                <el-col :lg="24" :md="24" style="text-align: center;">
                  <div class="ele-form-actions">
                    <el-button
                        type="primary"
                        class="ele-btn-icon"
                        @click="QueryList">查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <div class="Datalist-botton" style="margin-left: 20px">
                <!--<el-button class="custom-button" style="background: #FEA837!important;border-color: #FEA837!important;color: #FFFFFF!important;" @click="openEdit(null)">-->
                <!--  &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
                <!--  <i class="el-icon-tianjia" />-->
                <!--  <span style="vertical-align: middle">发起对账</span>-->
                <!--</el-button>-->
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;margin-left: 30px;">收入合计：</span>
                <span style="font-size: 18px;font-weight: 700;color: #fea837;">{{tongji.income}}</span>
                <span style="font-size: 18px;font-weight: 700;color: #4e4e4e;margin-left: 20px;">支出合计：</span>
                <span style="font-size: 18px;font-weight: 700;color: #FF2525;">{{tongji.disburse}}</span>
              </div>
            </div>
            <div>
              <!--<el-button>导出数据</el-button>-->
              <!--<el-button @click="ListSetup()">列表设置</el-button>-->
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
                ref="myTable"
                :columns="columns"
                :tableData="List"
                :cellStyle="cellStyle"
                :headerCellStyle="headerCellStyle"
                :rowClassName="rowClassName"
                :total="total"
                :customsFromWhere="where"
                :loading="loading"
                @select="select"
                @ChangeSize="ChangeSize"
                @currentChange="currentChange"
            >
            </my-table>

          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
            title="列表设置"
            :visible.sync="ListSetupShow"
            width="60%"
            center
            :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>
    </div>

  </div>
</template>

<script>

// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {moneylis, moneystatistics} from "@/api/finance";
import Vue from "vue";

export default {
  // 组件生效
  components: {
    PackageSortElement,
  },
  data(){
    return{
      //统计
      tongji:{},
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "项目",
          // type: "index",
          // width: "70"
          prop: "items_name",
          isShow: true
        },
        {
          label: "收入/支出",
          prop: "pm",
          isShow: true
        },
        {
          label: "单号",
          prop: "odd",
          isShow: true
        },
        // {
        //   label: "流水号",
        //   prop: "facilitator_name",
        //   isShow: true
        // },
        {
          label: "金额",
          prop: "order_money",
          isShow: true
        },
        {
          label: "交易时间",
          prop: "add_time",
          isShow: true
        },
        // {
        //   label: "交易状态",
        //   prop: "status",
        //   slot: 'status',
        //   isShow: true
        // },
        // {
        //   label: "交易说明",
        //   prop: "id",
        //   isShow: true
        // },
      ],

      // 列表设置弹窗
      ListSetupShow:false,

      //字典
      dict: {
        type:{}
      },

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    // 初始化dict
    this.$store.getters.dict.forEach(item => {
      if (item.name === '财务字典'){
        item.children.forEach(res => {
          Vue.set(this.dict.type, res.code, res.withChildren);
        })
      }
    });

    this.getTongji();

    this.getList();
  },

  methods:{
    //获取统计
    getTongji(){
      moneystatistics().then(res => {
        this.tongji = res.data;
      })
    },

    // 获取列表
    getList(){
      moneylis(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    //选择日期
    datechange(e){
      this.where.time = e;
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 删除
    del(row){
      console.log(row);
      this.$utils.confirm("确定要删除吗？", function () {
        // 执行一些需要的逻辑
        console.log('点击确定')
      }, "确定", 'warning');
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },


  }

}
</script>

<style lang="scss" scoped>

</style>
